// import './App.css';
import React, { useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

if (firebase.apps.length === 0) {
    firebase.initializeApp({
    apiKey: "AIzaSyDfMpw2SIY3f3_NYjzIly01ezg5K-fKsec",
    authDomain: "jlg-chat.firebaseapp.com",
    projectId: "jlg-chat",
    storageBucket: "jlg-chat.appspot.com",
    messagingSenderId: "923856762093",
    appId: "1:923856762093:web:f6343d4ac2525c703f48e4",
    measurementId: "G-2BW18ZHP4C"
    })
  }

const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header className="App-header">
      </header>

      <section>
        {user ? <ChatRoom /> : <SignIn />}
      </section>

    </div>
  );
}

function SignIn() {
  const SignInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <button onClick={SignInWithGoogle}>Sign in With Google</button>
  )
}
function SignOut() {
  return auth.currentUser && (

    <button onClick={() => auth.signOut()}>Sign Out</button>
  )
}

function ChatRoom() {
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limit(25);

  const [messages] = useCollectionData(query, {idField: 'id'});

  const [formValue, setFormValue] = useState('');


  const sendMessage = async(e) => {
    e.preventDefault();
    const { uid, photoURL } = auth.currentUser;

    const webHookUrl = 'https://hooks.slack.com/services/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';

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL
    })

    const data = {
      "text": `Name: ${formValue}`,
    }
  
    let res = await axios.post(webHookUrl, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [(data, headers) => {
        delete headers.post["Content-Type"]
        return data
      }]
    })
  
    if (res.status === 200) {
      alert("Message Sent!")
  
      //clear state so text boxes clear
      setFormValue('');
    } else {
      alert("There was an error.  Please try again later.")
      setFormValue('');
    }
  }


  return (
    <>
      <div>
        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
      </div>
      
      <form onSubmit={sendMessage}>
        <input value={formValue} onChange={(e) => setFormValue(e.target.value)}/>

        <button type="submit">Send</button>

      </form>
    </>
  )
}

function ChatMessage(props) {
  const { text, uid, photoURL } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (
    <div className={`message ${messageClass}`}>
      <img src={photoURL} />
      <p>{text}</p>
    </div>
  )
}

export default App;
